import { FullscreenAppContext } from '@wppopen/core'

import videoPreviewImgSrc from 'assets/video-preview.png'

import { Tenants } from '../constants/tenants'

export interface ChatItem {
  type?: 'user' | 'bot'
  message?: string
  actions?: { label: string; action?: () => void }[]
  videoUrl?: string
  imgUrl?: string
  showAvatar?: boolean
  timeout?: number
}

export const getChatByTenant = (osContext: FullscreenAppContext, handleContinueChat: () => void): ChatItem[] => {
  const tenantName = osContext?.tenant?.name || ''
  const userName = osContext?.userDetails?.firstname || ''

  return [
    {
      type: 'bot',
      message: `Hello ${userName},\n\nWelcome to WPP’s Open ${tenantName} Academy!`,
      showAvatar: true,
      timeout: 500,
    },
    {
      type: 'bot',
      message: `Is this your first visit to ${tenantName} Academy?`,
      actions: [{ label: 'YES', action: handleContinueChat }, { label: 'NO' }],
    },
    {
      type: 'user',
      message: 'Yes',
      timeout: 500,
    },
    {
      type: 'bot',
      message: `In that case ${userName}, we’re delighted to see you here for the first time!`,
      showAvatar: true,
      timeout: 500,
    },
    {
      type: 'bot',
      message: `${tenantName} Academy has been designed to help onboard you to working on the ${tenantName} account at WPP.`,
      actions: [{ label: 'Got it!', action: handleContinueChat }],
    },
    {
      type: 'user',
      message: 'Got it!',
      timeout: 500,
    },
    {
      type: 'bot',
      message: `${tenantName} is a long-term partner of WPP.${tenantName === Tenants.Henkel ? '\n\n' : ' '}You’ll settle in quickly and soon you’ll be doing some of the best work of your career with some incredibly talented colleagues across the world.`,
      showAvatar: true,
      actions: [{ label: 'Sounds great!', action: handleContinueChat }],
    },
    {
      type: 'user',
      message: 'Sounds great!',
      timeout: 500,
    },
    {
      type: 'bot',
      message: `We’ll be covering a wide range of topics in ${tenantName} Academy, but we’ll also make sure that you’re introduced to all of the right team members to answer any questions you may still have.`,
      showAvatar: true,
      actions: [{ label: 'Understood', action: handleContinueChat }],
    },
    {
      type: 'user',
      message: 'Understood',
      timeout: 500,
    },
    {
      type: 'bot',
      message: 'So let’s get started!',
      showAvatar: true,
      timeout: 500,
    },
    {
      type: 'bot',
      message: `${tenantName === Tenants.Santander ? 'Santander Academy offers engaging learning videos among other resources' : `${tenantName} Academy consists of engaging learning videos`}. We recommend you initially navigate these in order as they’ve been designed in a specific sequence. You can come back and reference them at any time!`,
      timeout: 500,
    },
    {
      type: 'bot',
      message: `There are downloadable guides supporting each episode to help your knowledge retention.${tenantName === Tenants.Henkel ? '\n\n' : ' '}We encourage you to take the quiz at the very end to test your knowledge – and earn a certificate which you can display proudly!`,
      timeout: 500,
    },
    {
      type: 'bot',
      message: `In addition, you’ll find templates and guides for creating key documents used for ${tenantName}.`,
      actions: [{ label: 'Got it!', action: handleContinueChat }],
    },
    {
      type: 'user',
      message: 'Got it!',
      timeout: 500,
    },
    {
      type: 'bot',
      message: `Alright, let’s get started! Here’s the first episode of ${tenantName} Academy. Dive on in!`,
      showAvatar: true,
      timeout: 500,
    },
    {
      ...(tenantName === Tenants.Santander
        ? {
            type: 'bot',
            imgUrl: videoPreviewImgSrc,
            timeout: 500,
          }
        : {
            type: 'bot',
            videoUrl: 'https://drive.google.com/file/d/18adQAER4FoQMdM_Kd19GPUadIpzgK6dU/preview',
            timeout: 500,
          }),
    },
    {
      type: 'bot',
      message:
        'Tip: If you need to leave the video in the middle, just pause it and your place will be saved for when you’re ready to resume.',
      actions: [{ label: 'CLICK HERE when finished', action: handleContinueChat }],
    },
    {
      ...(tenantName === Tenants.Henkel && {
        type: 'bot',
        actions: [
          { label: 'Henkel Media City' },
          { label: 'Introduction into Mental Availability' },
          { label: 'Category Entry Points' },
        ],
      }),
    },
  ]
}
