import {
  WppIconFilePdf,
  WppIconDataLine,
  WppIconDataUsage,
  WppIconPeople,
  WppIconDesign,
} from '@wppopen/components-library-react'
import { FullscreenAppContext } from '@wppopen/core'
import { ReactElement } from 'react'

import bmwLesson1ImgSrc from 'assets/bmw/lesson1.png'
import bmwLesson2ImgSrc from 'assets/bmw/lesson2.png'
import bmwLesson3ImgSrc from 'assets/bmw/lesson3.png'
import eaGamesAssistantAvatarImgSrc from 'assets/ea-games-assistant-avatar.png'
import eaGamesLesson1ImgSrc from 'assets/EAGames/lesson1.png'
import eaGamesLesson2ImgSrc from 'assets/EAGames/lesson2.png'
import eaGamesLesson3ImgSrc from 'assets/EAGames/lesson3.png'
import henkelAssistantAvatarImgSrc from 'assets/henkel-assistant-avatar.png'
import { ReactComponent as CheckIcon } from 'assets/icons/check-icon.svg'
import santanderAssistantAvatarImgSrc from 'assets/santander-assistant-avatar.png'
import sivaLesson1ImgSrc from 'assets/siva/lesson1.png'
import sivaLesson2ImgSrc from 'assets/siva/lesson2.png'
import sivaLesson3ImgSrc from 'assets/siva/lesson3.png'
import videoPreviewImgSrc from 'assets/video-preview.png'
import { Templates } from 'constants/template'
import { Tenants } from 'constants/tenants'

type ActionButtonVariant = 'primary' | 'secondary'

interface SelectPill {
  label: string
  disabled?: boolean
  icon?: ReactElement
  action?: () => void
}

export enum ComplexMessageType {
  Text = 'text',
  Image = 'image',
  Link = 'link',
  select = 'select',
}

interface ComplexMessage {
  type: ComplexMessageType
  text?: string
  imgUrl?: string
  link?: { label: string; icon: ReactElement; url: string; disabled?: boolean }
  selectPills?: SelectPill[]
}

export interface ChatItem {
  type?: 'user' | 'bot'
  message?: string
  complexMessage?: ComplexMessage[]
  actions?: { label: string; variant?: ActionButtonVariant; icon?: ReactElement; action?: () => void }[]
  videoUrl?: string
  imgUrl?: string
  showAvatar?: boolean
  timeout?: number
}

export interface Chat {
  template: Templates
  chat: ChatItem[]
}

const lessonImages: Partial<Record<Tenants, string[]>> = {
  [Tenants.BMW]: [bmwLesson1ImgSrc, bmwLesson2ImgSrc, bmwLesson3ImgSrc],
  [Tenants.EAGames]: [eaGamesLesson1ImgSrc, eaGamesLesson2ImgSrc, eaGamesLesson3ImgSrc],
  [(Tenants.SIVA, Tenants.WPP_PITCH_OS)]: [sivaLesson1ImgSrc, sivaLesson2ImgSrc, sivaLesson3ImgSrc],
}

const getLessonImage = (tenant: Tenants, lessonIndex: number) => {
  return lessonImages[tenant]?.[lessonIndex]
}

const getTenantDisplayName = (tenantName: string) => {
  switch (tenantName) {
    case Tenants.BMW:
      return 'BMW Group'
    case Tenants.WPP_PITCH_OS:
      return 'SIVA'
    default:
      return tenantName
  }
}

export const getChatByTenant = (osContext: FullscreenAppContext, handleContinueChat: () => void): Chat => {
  const tenantName = osContext?.tenant?.name || ''
  const displayTenantName = getTenantDisplayName(tenantName)
  const userName = osContext?.userDetails?.firstname || ''

  if (
    tenantName === Tenants.EAGames ||
    tenantName === Tenants.BMW ||
    tenantName === Tenants.SIVA ||
    tenantName === Tenants.WPP_PITCH_OS
  ) {
    return {
      template: Templates.Template2,
      chat: [
        {
          type: 'bot',
          message: `Hello ${userName},\nWelcome to WPP’s ${displayTenantName} Academy!\n\nIs this your first time exploring the ${displayTenantName} Academy?`,
          showAvatar: true,
          actions: [
            { label: 'Yes', variant: 'primary', action: handleContinueChat },
            { label: 'No', variant: 'secondary' },
          ],
        },
        {
          type: 'user',
          message: 'Yes',
          timeout: 500,
        },
        {
          type: 'bot',
          message: `In that case, ${userName}, we’re thrilled to have you here for the first time!\n\n${displayTenantName} Academy is designed to onboard you effectively to the exciting world of ${displayTenantName} marketing and collaboration.\n\nYour journey here will consist of short lessons, each introducing you to critical resources that will guide your work with ${displayTenantName}.`,
          showAvatar: true,
          actions: [{ label: 'Got it', variant: 'primary', action: handleContinueChat }],
        },
        {
          type: 'user',
          message: 'Got it',
          timeout: 500,
        },
        {
          type: 'bot',
          complexMessage: [
            { type: ComplexMessageType.Text, text: 'Let’s start with your first lesson.' },
            {
              type: ComplexMessageType.Image,
              imgUrl: getLessonImage(tenantName, 0),
            },
            {
              type: ComplexMessageType.Text,
              text: `This document explains all tasks, roles, responsibilities, and expected timelines for the entire ${displayTenantName} & Agency Relationship. Understanding this will set a strong foundation for your collaboration.`,
            },
            {
              type: ComplexMessageType.Link,
              link: {
                label:
                  tenantName === Tenants.BMW
                    ? `Understanding ${displayTenantName}’s Transformation Agenda & Die Neue Klasse Media`
                    : `${tenantName} Ways of working`,
                icon: <WppIconFilePdf color="var(--wpp-primary-color-500)" />,
                url: 'https://ea-games.os.wpp.com/files/hubs/6b914b98-92f0-4b55-aed3-a6396bb3b3db/folders/6cbdd0d5-4a82-4cd0-82d2-b9fb7955a1f1?fileId=ae91999e-21da-41fa-af43-8410f73ace6c',
                disabled:
                  tenantName === Tenants.BMW || tenantName === Tenants.SIVA || tenantName === Tenants.WPP_PITCH_OS,
              },
            },
          ],
          showAvatar: true,
          actions: [{ label: 'Done Lesson 1', variant: 'primary', icon: <CheckIcon />, action: handleContinueChat }],
        },
        {
          type: 'user',
          message: 'Done Lesson 1',
          timeout: 500,
        },
        {
          type: 'bot',
          complexMessage: [
            {
              type: ComplexMessageType.Text,
              text: `Great work, ${userName}! Now, let’s tailor your learning based on your role.\n\nWhat’s your specialty?`,
            },
            {
              type: ComplexMessageType.select,
              selectPills: [
                { label: 'Planner', icon: <WppIconDataLine />, action: handleContinueChat },
                { label: 'Strategist', icon: <WppIconDataUsage />, disabled: true },
                { label: 'Campaign Manager', icon: <WppIconPeople />, disabled: true },
                { label: 'Creative Lead', icon: <WppIconDesign />, disabled: true },
              ],
            },
          ],
          showAvatar: true,
        },
        {
          type: 'user',
          message: 'Planner',
          timeout: 500,
        },
        {
          type: 'bot',
          complexMessage: [
            {
              type: ComplexMessageType.Text,
              text: `Perfect, as a Planner, your next lesson focuses on the ${displayTenantName} Media Plan Process`,
            },
            {
              type: ComplexMessageType.Image,
              imgUrl: getLessonImage(tenantName, 1),
            },
            {
              type: ComplexMessageType.Text,
              text: `This document outlines our planning methodology and the inputs required to work efficiently with ${displayTenantName}’s platform.`,
            },
            {
              type: ComplexMessageType.Link,
              link: {
                label: `${displayTenantName} Media Plan Process`,
                icon: <WppIconFilePdf color="var(--wpp-primary-color-500)" />,
                url: 'https://ea-games.os.wpp.com/files/hubs/6b914b98-92f0-4b55-aed3-a6396bb3b3db/folders/6cbdd0d5-4a82-4cd0-82d2-b9fb7955a1f1?fileId=5a78a78e-c61c-4f44-97fe-37721368834a',
                disabled:
                  tenantName === Tenants.BMW || tenantName === Tenants.SIVA || tenantName === Tenants.WPP_PITCH_OS,
              },
            },
          ],
          showAvatar: true,
          actions: [{ label: 'Done Lesson 2', variant: 'primary', icon: <CheckIcon />, action: handleContinueChat }],
        },
        {
          type: 'user',
          message: 'Done Lesson 2',
          timeout: 500,
        },
        {
          type: 'bot',
          complexMessage: [
            {
              type: ComplexMessageType.Text,
              text: `You’re doing great, ${userName}!\nFor your third and final lesson, let’s dive into campaign execution.`,
            },
            {
              type: ComplexMessageType.Image,
              imgUrl: getLessonImage(tenantName, 2),
            },
            {
              type: ComplexMessageType.Text,
              text: 'This document explains our taxonomy and cleanliness process, complete with detailed troubleshooting scenarios to help ensure flawless campaign activation.',
            },
            {
              type: ComplexMessageType.Link,
              link: {
                label: `${displayTenantName} Taxonomy & Cleanliness Process`,
                icon: <WppIconFilePdf color="var(--wpp-primary-color-500)" />,
                url: 'https://ea-games.os.wpp.com/files/hubs/6b914b98-92f0-4b55-aed3-a6396bb3b3db/folders/6cbdd0d5-4a82-4cd0-82d2-b9fb7955a1f1?fileId=87e3d2ec-e2f6-42cc-a53d-3398fa4f79f6',
                disabled:
                  tenantName === Tenants.BMW || tenantName === Tenants.SIVA || tenantName === Tenants.WPP_PITCH_OS,
              },
            },
          ],
          showAvatar: true,
          actions: [{ label: 'Done Lesson 3', variant: 'primary', icon: <CheckIcon /> }],
        },
      ],
    }
  }

  return {
    template: Templates.Template1,
    chat: [
      {
        type: 'bot',
        message: `Hello ${userName},\n\nWelcome to WPP’s Open ${tenantName} Academy!`,
        showAvatar: true,
        timeout: 500,
      },
      {
        type: 'bot',
        message: `Is this your first visit to ${tenantName} Academy?`,
        actions: [{ label: 'YES', action: handleContinueChat }, { label: 'NO' }],
      },
      {
        type: 'user',
        message: 'Yes',
        timeout: 500,
      },
      {
        type: 'bot',
        message: `In that case ${userName}, we’re delighted to see you here for the first time!`,
        showAvatar: true,
        timeout: 500,
      },
      {
        type: 'bot',
        message: `${tenantName} Academy has been designed to help onboard you to working on the ${tenantName} account at WPP.`,
        actions: [{ label: 'Got it!', action: handleContinueChat }],
      },
      {
        type: 'user',
        message: 'Got it!',
        timeout: 500,
      },
      {
        type: 'bot',
        message: `${tenantName} is a long-term partner of WPP.${tenantName === Tenants.Henkel ? '\n\n' : ' '}You’ll settle in quickly and soon you’ll be doing some of the best work of your career with some incredibly talented colleagues across the world.`,
        showAvatar: true,
        actions: [{ label: 'Sounds great!', action: handleContinueChat }],
      },
      {
        type: 'user',
        message: 'Sounds great!',
        timeout: 500,
      },
      {
        type: 'bot',
        message: `We’ll be covering a wide range of topics in ${tenantName} Academy, but we’ll also make sure that you’re introduced to all of the right team members to answer any questions you may still have.`,
        showAvatar: true,
        actions: [{ label: 'Understood', action: handleContinueChat }],
      },
      {
        type: 'user',
        message: 'Understood',
        timeout: 500,
      },
      {
        type: 'bot',
        message: 'So let’s get started!',
        showAvatar: true,
        timeout: 500,
      },
      {
        type: 'bot',
        message: `${tenantName === Tenants.Santander ? 'Santander Academy offers engaging learning videos among other resources' : `${tenantName} Academy consists of engaging learning videos`}. We recommend you initially navigate these in order as they’ve been designed in a specific sequence. You can come back and reference them at any time!`,
        timeout: 500,
      },
      {
        type: 'bot',
        message: `There are downloadable guides supporting each episode to help your knowledge retention.${tenantName === Tenants.Henkel ? '\n\n' : ' '}We encourage you to take the quiz at the very end to test your knowledge – and earn a certificate which you can display proudly!`,
        timeout: 500,
      },
      {
        type: 'bot',
        message: `In addition, you’ll find templates and guides for creating key documents used for ${tenantName}.`,
        actions: [{ label: 'Got it!', action: handleContinueChat }],
      },
      {
        type: 'user',
        message: 'Got it!',
        timeout: 500,
      },
      {
        type: 'bot',
        message: `Alright, let’s get started! Here’s the first episode of ${tenantName} Academy. Dive on in!`,
        showAvatar: true,
        timeout: 500,
      },
      {
        ...(tenantName === Tenants.Santander
          ? {
              type: 'bot',
              imgUrl: videoPreviewImgSrc,
              timeout: 500,
            }
          : {
              type: 'bot',
              videoUrl: 'https://drive.google.com/file/d/18adQAER4FoQMdM_Kd19GPUadIpzgK6dU/preview',
              timeout: 500,
            }),
      },
      {
        type: 'bot',
        message:
          'Tip: If you need to leave the video in the middle, just pause it and your place will be saved for when you’re ready to resume.',
        actions: [{ label: 'CLICK HERE when finished', action: handleContinueChat }],
      },
      {
        ...(tenantName === Tenants.Henkel && {
          type: 'bot',
          actions: [
            { label: 'Henkel Media City' },
            { label: 'Introduction into Mental Availability' },
            { label: 'Category Entry Points' },
          ],
        }),
      },
    ],
  }
}

export const getBotAvatarImg = (tenantName?: string) => {
  switch (tenantName) {
    case Tenants.Henkel:
      return henkelAssistantAvatarImgSrc
    case Tenants.Santander:
      return santanderAssistantAvatarImgSrc
    case Tenants.EAGames:
      return eaGamesAssistantAvatarImgSrc
    default:
      return henkelAssistantAvatarImgSrc
  }
}
