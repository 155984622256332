import henkelAssistantAvatarImgSrc from 'assets/henkel-assistant-avatar.png'
import santanderAssistantAvatarImgSrc from 'assets/santander-assistant-avatar.png'
import { Tenants } from 'constants/tenants'

export const getAvatarImg = (tenantName?: string) => {
  switch (tenantName) {
    case Tenants.Henkel:
      return henkelAssistantAvatarImgSrc
    case Tenants.Santander:
      return santanderAssistantAvatarImgSrc
    default:
      return henkelAssistantAvatarImgSrc
  }
}
