import { WppTypography } from '@wppopen/components-library-react'
import { useOs } from '@wppopen/react'
import clsx from 'clsx'
import { useState } from 'react'

import { ChatItem } from 'app/utils'
import { Avatar } from 'components/avatar/Avatar'
import styles from 'components/botChatBubble/BotChatBubble.module.scss'
import { getAvatarImg } from 'components/botChatBubble/utils'
import { Flex } from 'components/common/flex/Flex'

export const BotChatBubble = ({ message, actions, videoUrl, showAvatar, imgUrl }: ChatItem) => {
  const { osContext } = useOs()
  const [showActions, setShowActions] = useState(true)

  const handleActionClick = (action: any) => {
    if (!action) return
    setShowActions(false)
    action()
  }

  if (videoUrl) {
    return (
      // <iframe title="video" src={videoUrl} width="640" height="370" allow="autoplay" className={styles.videoIframe} />
      <iframe title="video" src={videoUrl} allow="autoplay" className={styles.videoIframe} />
    )
  }

  if (imgUrl) {
    return <img title="video" src={imgUrl} className={styles.videoPreviewImg} alt="video preview" />
  }

  return (
    <div>
      <Flex gap={12}>
        {showAvatar && (
          <div className={styles.avatarContainer}>
            <Avatar size="l" src={getAvatarImg(osContext?.tenant?.name)} />
          </div>
        )}
        <div>
          {message && (
            <div className={clsx(styles.bubble, !showAvatar && styles.noAvatarBubble)}>
              <WppTypography type="m-body" className={styles.message}>
                {message}
              </WppTypography>
            </div>
          )}
          {actions && showActions && (
            <Flex gap={8} className={clsx(styles.actions, !showAvatar && styles.noAvatarActions)}>
              {actions.map((action, index) => (
                <button key={index} className={styles.actionBtn} onClick={() => handleActionClick(action?.action)}>
                  <WppTypography type="m-strong">{action.label}</WppTypography>
                </button>
              ))}
            </Flex>
          )}
        </div>
      </Flex>
    </div>
  )
}
