import { WppTypography } from '@wppopen/components-library-react'
import { useOs } from '@wppopen/react'
import clsx from 'clsx'

import { Flex } from 'components/common/flex/Flex'
import { Avatar } from 'components/template2/avatar/Avatar'
import styles from 'components/template2/userChatBubble/UserChatBubble.module.scss'
import { Tenants } from 'constants/tenants'

interface Props {
  message?: string
}

export const UserChatBubble = ({ message }: Props) => {
  const { osContext } = useOs()

  return (
    <Flex
      justify="end"
      className={clsx(styles.bubbleContainer, {
        [styles.sivaTheme]: osContext?.tenant?.name === Tenants.WPP_PITCH_OS,
      })}
    >
      <Flex gap={8}>
        <div className={styles.bubble}>
          <WppTypography type="s-body" className={styles.message}>
            {message}
          </WppTypography>
        </div>
        <Avatar size="s" name={osContext?.userDetails?.firstname} />
      </Flex>
    </Flex>
  )
}
