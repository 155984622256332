import { WppTypography, WppButton, WppPill } from '@wppopen/components-library-react'
import { useOs } from '@wppopen/react'
import clsx from 'clsx'
import { Fragment } from 'react'

import { ChatItem, getBotAvatarImg, ComplexMessageType } from 'app/utils'
import { Flex } from 'components/common/flex/Flex'
import { Avatar } from 'components/template2/avatar/Avatar'
import styles from 'components/template2/botChatBubble/BotChatBubble.module.scss'
import { Tenants } from 'constants/tenants'

export const BotChatBubble = ({ message, complexMessage, actions, showAvatar }: ChatItem) => {
  const { osContext } = useOs()

  const handleActionClick = (action: any) => {
    if (!action) return
    action()
  }

  return (
    <div
      className={clsx({
        [styles.sivaTheme]: osContext?.tenant?.name === Tenants.WPP_PITCH_OS,
      })}
    >
      <Flex gap={8}>
        {showAvatar && (
          <div className={styles.avatarContainer}>
            <Avatar size="s" src={getBotAvatarImg(osContext?.tenant?.name)} />
          </div>
        )}
        <div>
          <div className={styles.bubble}>
            {message && (
              <WppTypography type="s-body" className={styles.message}>
                {message}
              </WppTypography>
            )}
            {complexMessage?.map((messagePart, index) => (
              <Fragment key={index}>
                {messagePart.type === ComplexMessageType.Text && (
                  <div>
                    <WppTypography type="s-body" className={styles.message}>
                      {messagePart.text}
                    </WppTypography>
                  </div>
                )}
                {messagePart.type === ComplexMessageType.Image && (
                  <img src={messagePart.imgUrl} className={styles.image} alt="" />
                )}
                {messagePart.type === ComplexMessageType.Link && (
                  <Flex gap={8} align="center" className={styles.linkContainer}>
                    {messagePart.link?.icon}
                    <a
                      href={messagePart.link?.url}
                      target="_blank"
                      rel="noreferrer"
                      className={clsx({ [styles.disabledPointerEvents]: messagePart.link?.disabled })}
                    >
                      <WppTypography type="s-body" className={styles.linkLabel}>
                        {messagePart.link?.label}
                      </WppTypography>
                    </a>
                  </Flex>
                )}
                {messagePart.type === ComplexMessageType.select && (
                  <Flex gap={8} wrap="wrap" className={styles.selectContainer}>
                    {messagePart.selectPills?.map((pill, index) => (
                      <WppPill key={index} onClick={pill.action} disabled={pill.disabled}>
                        <Flex gap={4} align="center">
                          {pill.icon}
                          {pill.label}
                        </Flex>
                      </WppPill>
                    ))}
                  </Flex>
                )}
              </Fragment>
            ))}
            {actions && (
              <Flex gap={8} className={styles.actions}>
                {actions.map((action, index) => (
                  <WppButton
                    key={index}
                    className={clsx(styles.actionBtn, {
                      [styles.actionBtnPrimary]:
                        osContext?.tenant?.name === Tenants.WPP_PITCH_OS && action.variant === 'primary',
                      [styles.actionBtnSecondary]:
                        osContext?.tenant?.name === Tenants.WPP_PITCH_OS && action.variant === 'secondary',
                    })}
                    variant={action.variant || 'primary'}
                    onClick={() => handleActionClick(action?.action)}
                  >
                    <Flex justify="between" align="center" gap={10}>
                      {action.icon}
                      {action.label}
                    </Flex>
                  </WppButton>
                ))}
              </Flex>
            )}
          </div>
        </div>
      </Flex>
    </div>
  )
}
