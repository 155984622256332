import { useOs } from '@wppopen/react'
import { Fragment, useState, useEffect, useRef } from 'react'
import { BrowserRouter } from 'react-router-dom'

import styles from 'app/App.module.scss'
import { getChatByTenant } from 'app/utils'
import { BotChatBubble } from 'components/botChatBubble/BotChatBubble'
import { UserChatBubble } from 'components/userChatBubble/UserChatBubble'

export function App() {
  const { osContext } = useOs()

  const chatEndRef = useRef<HTMLDivElement>(null)

  const [chatProgressIndex, setChatProgressIndex] = useState(0)

  const handleContinueChat = () => {
    if (chatProgressIndex === chat.length - 1) return
    setChatProgressIndex(chatProgressIndex + 1)
  }

  const chat = getChatByTenant(osContext, handleContinueChat)

  const scrollToBottom = () => {
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  useEffect(() => {
    document.body.classList.add(styles.root)

    return () => document.body.classList.remove(styles.root)
  }, [])

  useEffect(() => {
    scrollToBottom()

    if (chatProgressIndex === chat.length - 1) return

    if (chat[chatProgressIndex]?.timeout) {
      setTimeout(() => {
        setChatProgressIndex(chatProgressIndex + 1)
      }, chat[chatProgressIndex].timeout)
    }
    // eslint-disable-next-line
  }, [chatProgressIndex])

  return (
    <BrowserRouter basename={osContext.baseUrl}>
      <div className={styles.container}>
        {chat.map((chatItem, index) => (
          <Fragment key={index}>
            {index <= chatProgressIndex && (
              <>
                {chatItem.type === 'bot' && (
                  <BotChatBubble
                    message={chatItem?.message}
                    actions={chatItem?.actions}
                    imgUrl={chatItem?.imgUrl}
                    videoUrl={chatItem?.videoUrl}
                    showAvatar={chatItem?.showAvatar}
                  />
                )}
                {chatItem.type === 'user' && <UserChatBubble message={chatItem?.message} />}
              </>
            )}
          </Fragment>
        ))}
        <div ref={chatEndRef} />
      </div>
    </BrowserRouter>
  )
}
